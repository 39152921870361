<template>
    <div class="rankListComponentBox">
        <van-swipe vertical :autoplay="5000" loop :show-indicators="false">
            <van-swipe-item v-for="item in rankListData" :key="item.id">
                <div class="rankListItem" @click="toGame(item)">
                    <img style="margin: 4px 4px 0 0;width: 18px; vertical-align: middle;text-align: center;"
                        src="../../assets/sound.png" alt="">
                    <span class="rankListBonusInfoItemText">
                        {{ $t("common.home.congratulations") }}
                        <span style="color: #27b507;"> {{ item.username }}</span>
                        {{ item.description }}
                        <!-- {{ $t("common.home.in") }}
                        {{ item.gameName }} -->
                    </span>
                </div>
            </van-swipe-item>
        </van-swipe>
    </div>
</template>

<script>
import { Toast } from "vant";
import { mapState, mapActions } from "vuex";
export default {
    data() {
        return {
            rankListData: [],
            game: {},
        }
    },
    methods: {

        showLoginActionSheet(e) {
            this.$store.commit("showLoginActionSheet", e);
        },

        deWeight(arr) {
            for (let i = 0; i < arr.length - 1; i++) {
                for (let j = i + 1; j < arr.length; j++) {
                    if (arr[i].id == arr[j].id) {
                        arr.splice(j, 1);
                        j--;
                    }
                }
            }
            return arr;
        },

        toGame(e) {
            let gameItem = this.getGameItem(e.gid)
            this.$axios
                .get(this.$Host + "/client/game/openGame?id=" + gameItem.id)
                .then((res) => {
                    let arr = localStorage.getItem("7topgame.com-user-history");
                    if (arr == null) {
                        arr = [];
                        arr.push(gameItem);
                        localStorage.setItem(
                            "7topgame.com-user-history",
                            JSON.stringify(arr)
                        );
                        return;
                    } else {
                        let oldArr = JSON.parse(arr);
                        oldArr.unshift(gameItem);
                        let newArr = this.deWeight(oldArr);
                        localStorage.setItem(
                            "7topgame.com-user-history",
                            JSON.stringify(newArr)
                        );
                    }
                })
                .catch((err) => { });

            if (gameItem.isHot == '1') {
                let userInfo = JSON.parse(localStorage.getItem('7topgame.com-user-info'))
                if (userInfo.isTourist) {
                    this.showLoginActionSheet(true)
                    Toast(this.$t("common.userAction.cannotPlayGame"))
                    return
                }
            }

            setTimeout(() => {
                location.href = gameItem.gameUrl;
            }, 500);

        },

        findRankListByLang() {
            let l = localStorage.getItem("7topgame.com-user-lang")
            this.$axios
                .get(this.$Host + "/client/rankList/findByLangSortByGameId?lang=" + l)
                .then((res) => {
                    if (res.data.code == 10041) {
                        this.rankListData = res.data.data;
                        sessionStorage.setItem(
                            "7topgame.com-rankList",
                            JSON.stringify(res.data.data)
                        );
                        return;
                    }
                    Toast(this.$t("common.axios.failed"))
                })
                .catch((err) => {
                    Toast(this.$t("common.axios.failed"))
                });
        },

        getGameItem(gid) {
            let gameList = JSON.parse(sessionStorage.getItem('7topgame.com-game-list'))
            for (let i = 0; i < gameList.length; i++) {
                const ele = gameList[i];
                if (ele.id == gid) {
                    return ele
                }
            }
        },
    },
    mounted() {
        this.findRankListByLang()
    },
}
</script>

<style scoped>
.rankListComponentBox {
    margin: 0 auto;
    width: 100%;
}

.rankListItem {
    width: 100%;
    height: 100%;
    text-align: center;
}

.rankListBonusInfoItemText {
    margin: 0 auto;
    width: 100%;
    height: 31px;
    line-height: 31px;
    color: white;
    font-size: 8px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    /* text-overflow: ellipsis; */
}

.rankListBonusInfoItemIcon {
    /* position: absolute;
    left: 3px;
    top: 5px; */
    width: 21px;
    height: 21px;
    overflow: hidden;
}
</style>