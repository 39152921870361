<template>
  <div>
    <div v-if="!isByClassification" class="gameItemBoxNormal" @click="toGame">
      <div class="isCollectGameItem" @click="stopClickEvent">
        <img style="width: 100%" v-if="isCollect" src="../../assets/like-selected.png" alt="" @click="del" />
        <img style="width: 100%" v-else src="../../assets/like-no-select.png" alt="" @click="collect" />
      </div>

      <div v-if="game.isHot == '1'" class="iconTopNormal">
        {{ $t("common.home.hot") }}
      </div>
      <img class="gameImg" :src="game.image" alt="" />

      <div class="gameItemBackImg">
        <img src="../../assets/gameItemBack.png" style="width: 100%; height: 100%" alt="" />
      </div>

      <div class="gameName">
        {{ game.gameName }}
      </div>
    </div>

    <div v-else class="gameItemBoxMax" @click="toGame">
      <div class="gameItemBackImg">
        <img src="../../assets/gameItemBack.png" style="width: 100%; height: 100%" alt="" />
      </div>
      <div class="gameName">
        {{ game.gameName }}
      </div>
      <div class="isCollectGameItem" @click="stopClickEvent">
        <img style="width: 100%" v-if="isCollect" src="../../assets/like-selected.png" alt="" @click="del" />
        <img style="width: 100%" v-else src="../../assets/like-no-select.png" alt="" @click="collect" />
      </div>
      <div v-if="game.isHot == '1'" class="iconTopNormal">
        {{ $t("common.home.hot") }}
      </div>
      <div class="gameItemImgBox">
        <img class="gameImg" :src="game.image" alt="" />
      </div>
    </div>

    <van-action-sheet v-model="showGameInfo" style="background-color: #1c2028">
      <div class="gameItemContent">
        <div class="gameItemInfo">
          <img class="gameImg" :src="game.image" alt="" />
          <div class="gameItem">
            <p></p>
            <div class="gameItemName">
              {{ game.gameName }}
            </div>
            <div class="description">
              {{ $t("common.userAction.start") }}
            </div>
            <div class="played">
              {{ game.openNum }} {{ $t("common.userAction.played") }}
            </div>
          </div>
        </div>
        <div class="gameItemStart">
          <van-button icon="play-circle" style="font-size: 20px" color="#9338FA" type="info" block @click="toGame">{{
            $t("common.button.play") }}</van-button>
        </div>
        <div class="collect">
          <van-icon name="like-o" v-if="!isCollect" @click="collect" size="25px" />
          <van-icon name="like" v-else color="red" @click="del" size="25px" />
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import { mapState, mapActions } from "vuex";
import { Toast } from "vant";
export default {
  props: {
    game: {
      type: Object,
      default: () => { },
    },
  },

  data() {
    return {
      show: false,
      showGameInfo: false,
      gameItem: {},
      isCollect: false,
      userInfo: {},
      isByClassification: false,
    };
  },

  methods: {

    showLoginActionSheet(e) {
      this.$store.commit("showLoginActionSheet", e);
    },

    stopClickEvent() {
      event.stopImmediatePropagation();
    },

    del() {
      let userCollect = {
        tid: this.userInfo.id,
        gameId: this.game.id,
      };
      this.$axios
        .post(this.$Host + "/client/tourist/collect/del", userCollect)
        .then((res) => {
          if (res.data.code == 10021) {
            let localUserCollect = JSON.parse(
              localStorage.getItem("7topgame.com-user-collect")
            );
            if (localUserCollect != null) {
              for (let i = 0; i < localUserCollect.length; i++) {
                const element = localUserCollect[i];
                if (userCollect.gameId == element.gameId) {
                  localUserCollect.splice(i, 1);
                  break;
                }
              }
            }
            localStorage.setItem(
              "7topgame.com-user-collect",
              JSON.stringify(localUserCollect)
            );
            this.isCollect = false;
            Toast(this.$t("common.axios.successful"));
            return;
          }
          Toast(this.$t("common.axios.failed"));
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"));
        });
    },

    collect() {
      if (this.userInfo == null || Object.keys(this.userInfo).length == 0) {
        Toast(this.$t("common.axios.noLogin"));
        setTimeout(() => {
          this.$router.push("/login");
        }, 1500);
        return;
      }

      let userCollect = {
        tid: this.userInfo.id,
        gameId: this.game.id,
      };

      const analytics = getAnalytics();

      logEvent(analytics, "collect", {
        // content_type: 'link',
        uid: this.userInfo.id,
        gid: this.game.id,
      });

      this.$axios
        .post(this.$Host + "/client/tourist/collect/insert", userCollect)
        .then((res) => {
          if (res.data.code == 10011) {
            localStorage.setItem(
              "7topgame.com-user-collect",
              JSON.stringify(res.data.data)
            );
            this.isCollect = true;
            Toast(this.$t("common.axios.successful"));
            return;
          }
          Toast(this.$t("common.axios.failed"));
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"));
        });
    },

    isCollectGame() {
      let collect = JSON.parse(
        localStorage.getItem("7topgame.com-user-collect")
      );
      if (collect == null) {
        return;
      }
      for (let i = 0; i < collect.length; i++) {
        const element = collect[i];
        if (element.gameId == this.game.id) {
          this.isCollect = true;
          break;
        } else {
          this.isCollect = false;
        }
      }
    },

    showGameInfoActionSheet() {
      this.showGameInfo = true;
    },

    deWeight(arr) {
      for (let i = 0; i < arr.length - 1; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i].id == arr[j].id) {
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },

    toGame() {
      const analytics = getAnalytics();
      logEvent(analytics, "open_game", {
        // content_type: 'link',
        game_name: this.game.gameName,
      });

      Toast.loading({
        forbidClick: true,
        loadingType: "spinner",
      });

      this.$axios
        .get(this.$Host + "/client/game/openGame?id=" + this.game.id)
        .then((res) => {
          let arr = localStorage.getItem("7topgame.com-user-history");
          if (arr == null) {
            arr = [];
            arr.push(this.game);
            localStorage.setItem(
              "7topgame.com-user-history",
              JSON.stringify(arr)
            );
            return;
          } else {
            let oldArr = JSON.parse(arr);
            oldArr.unshift(this.game);
            let newArr = this.deWeight(oldArr);
            localStorage.setItem(
              "7topgame.com-user-history",
              JSON.stringify(newArr)
            );
          }
        })
        .catch((err) => { });
      if (this.game.isHot == '1') {
        if (this.userInfo.isTourist) {
          this.showLoginActionSheet(true)
          Toast(this.$t("common.userAction.cannotPlayGame"))
          return
        }
      }

      setTimeout(() => {
        location.href = this.game.gameUrl;
      }, 500);

    },

    changeGameItemWidth() {
      let queryParams = this.$route.query.classification;

      if (queryParams == undefined) {
        this.isByClassification = false;
        return;
      }
      this.isByClassification = true;
    },
  },

  components: {},
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("7topgame.com-user-info"));
    this.isCollectGame();
    this.changeGameItemWidth();
    //   let mobileDeviceSize = document.querySelector(".gameItemBoxNormal");
    //   let backImg = document.querySelector(".gameItemBackImg");
    //   let iconHot = document.querySelector(".iconTopNormal");
    // let width = window.innerWidth;

    //   if (width > 400 && width < 550) {
    //     // mobileDeviceSize.className = "gameItemBoxMax";
    //   } else if (width > 370 && width < 400) {
    //     mobileDeviceSize.className = "gameItemBoxNormal";
    //     iconHot.className = "iconTopNormal";
    //     backImg.className = "gameItemBackImg";
    //   } else if (width <= 370) {
    //     mobileDeviceSize.className = "gameItemBoxMin";
    //     iconHot.className = "iconTopMin";
    //     backImg.className = "gameItemBackImgMin";
    //   } else if (width > 550) {
    //     // mobileDeviceSize.className = "gameItemBoxPc";
    //   }
  },
};
</script>

<style>
.gameItemBoxMin {
  position: relative;
  width: 100%;
  height: 100%;
  /* min-height: 130px; */
  border-radius: 10px;
  overflow: hidden;
}

.gameItemBoxNormal {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  /* min-height: 130px; */
  border-radius: 10px;
  overflow: hidden;
}

.gameItemBoxNormal .isCollectGameItem {
  position: absolute;
  top: 3%;
  right: 3%;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.gameItemBoxMax {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-height: 117px;
  /* min-height: 130px; */
  border-radius: 10px;
  overflow: hidden;
}

.gameItemBoxMax .isCollectGameItem {
  position: absolute;
  top: 2%;
  right: 5%;
  width: 25px;
  height: 25px;
  border-radius: 10px;
  z-index: 1;
}

/* .gameItemBoxPc {
  position: relative;
  width: 200px;
  height: 220px;
  border-radius: 10px;
} */

.gameItemBoxPc .gameName {
  font-size: 18px;
  line-height: 50px;
}

.iconTopNormal {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40px;
  height: 20px;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 10px;
  background-color: orange;
  color: white;
  font-weight: bold;
  z-index: 1;
  background-image: linear-gradient(to bottom, #9338fa, #c000f4 100%);
}

.iconTopMin {
  position: absolute;
  top: 0px;
  left: 4px;
  width: 40px;
  height: 20px;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  background-color: orange;
  color: white;
  font-weight: bold;
  /* z-index: -1; */
  background-image: linear-gradient(to bottom, #9338fa, #c000f4 100%);
}

.gameName {
  position: absolute;
  bottom: 0;
  /* margin-top: 100px; */
  width: 100%;
  /* height: 23%; */
  color: white;
  font-size: 12px;
  text-align: center;
  height: 30px;
  line-height: 25px;
  border-radius: 7%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.gameItemBoxMax .gameName {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  font-size: 12px;
  text-align: center;
  height: 30px;
  line-height: 35px;
  border-radius: 7%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 1;
}

.gameImg {
  /* top: 50%;
  left: 50%;
  max-height: 100%;
  transform: translate(-50%, -50%); */
  width: 100%;
  /* max-height: 117px; */
  border-radius: 7.5%;
  overflow: hidden;
}

.gameItemImgBox .gameImg {
  position: absolute;
  left: 0;
  object-fit: cover;
  width: 100%;
  max-height: 117px;
  border-radius: 15px;
  overflow: hidden;
}

.gameItemContent {
  padding: 16px 16px 40px 16px;
  position: relative;
}

.gameItemInfo {
  padding: 10px 0;
  display: flex;
  margin: 20px auto;
  margin-left: 20px;
  width: 93%;
  height: 120px;
  font-size: 16px;
  /* font-weight: bold; */
  color: white;
}

.gameItemImg {
  flex: 0.7;
  border-radius: 7.5%;
  overflow: hidden;
}

/* .gameItemBackImgMin {
  position: absolute;
  bottom: -1px;
  left: 3px;
  margin: 0 auto;
  width: 100%;
  height: 45%;
} */

.gameItemBackImg {
  position: absolute;
  bottom: 2px;
  left: -1px;
  margin: 0 auto;
  width: 102%;
  height: 45%;
}

.gameItemBoxMax .gameItemBackImg {
  position: absolute;
  bottom: -1px;
  left: -1px;
  margin: 0 auto;
  width: 102%;
  min-height: 40px;
  z-index: 1;
}

.gameItem {
  flex: 1.3;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.gameItemStart {
  margin: 0px auto;
  width: 95%;
  border-radius: 6px;
  overflow: hidden;
}

.description {
  flex: 1;
  width: 100%;
  height: 30%;
  margin-top: 10px;
  font-size: 14px;
  word-wrap: break-word;
  /* 允许在单词内部换行 */
  white-space: wrap;
  color: rgb(219, 224, 207);
}

.gameItemName {
  flex: 1;
  text-align: center;
  font-weight: bold;
}

.played {
  flex: 1;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  /* overflow: hidden; */
  color: rgb(219, 224, 207);
}

.collect {
  position: absolute;
  right: 5%;
  top: 16%;
  /* background-color: red; */
  width: 30px;
  height: 30px;
}
</style>
