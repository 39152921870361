<template>
  <div>

    <router-view></router-view>

  </div>
</template>

<script>
import { mapState } from "vuex";
import request from "./request/request";
export default {
  data() {
    return {
      isInstall: this.$store.state.isInstallApp
    }
  },

  created() {

  },

  methods: {
    showInstallTip(e) {
      this.$store.commit("showInstallTip", e);
    },


    webSocketInit() {
      let userInfo = localStorage.getItem("7topgame.com-user-info");
      if (userInfo == '{}' || userInfo == null) {
        return;
      }
      let uid = JSON.parse(userInfo).id;
      const socket = new WebSocket(request.WEBSOCKET + uid);

      socket.onopen = function (event) {
        console.log("WebSocket connected");
      };

      socket.onmessage = function (event) {
        console.log("receive:", event);
      };

      socket.onclose = function (event) {
        console.log("WebSocket offline");
      };

      socket.onerror = function (error) {
        console.error("WebSocket err:", error);
      };
      return socket
    },

    initialApp(e) {
      this.$store.commit("initialApp", e);
    },

    // 判断是否在pwa内
    isPWA() {
      const displayModes = ['fullscreen', 'standalone', 'minimal-ui']
      const matchesPwa = displayModes.some(
        displayMode => window.matchMedia('(display-mode: ' + displayMode + ')').matches
      )
      return (
        matchesPwa ||
        window.navigator?.standalone ||
        document.referrer.includes('android-app://')
      )
    },
  },

  mounted() {
    let that = this
    window.addEventListener('beforeinstallprompt', function (e) {
      that.initialApp(e)
      if (!that.isPWA()) {
        if (e != null) {
          that.showInstallTip(true)
        }
      }
    });



    this.webSocketInit();
    setInterval(() => {
      this.webSocketInit();
    }, 50000);

  },

}
</script>

<style></style>
