//英文语言包en.js

export default {
    common: {
        // 英语登录界面语言
        login: {
            bannerWord: 'WELCOME TO SIGN IN',
            account: 'Email',
            verifyCode: 'Verify code',
            sendCode: "Send code",
            password: "Password",
            signInWithPassword: 'Sign in with password',
            signInWithEmail: 'Sign in with email',
            signIn: 'Sign in',
            signInWithTourist: 'LOG IN AS A GUEST',
            signInWithGoogle: 'LOG IN VIA GOOGLE',
            inputTip: 'Enter your nickname (Required)'
        },
        // 英语注册界面语言
        register: {
            bannerWord: 'Welcome to sign up',
            account: 'Email',
            verifyCode: 'Verify code',
            sendCode: "Send code",
            password: "Password",
            signInWithPassword: 'Sign up with password',
            signInWithEmail: 'Sign up with email',
            signUp: 'Sign up',
        },
        // 英语主界面语言
        home: {
            signIn: 'Sign in',
            signUp: 'Sign up',
            recommend: 'recommend',
            more: 'More',
            hot: 'HOT',
            new: 'NEW',
            search: 'Search',
            result: 'Search result',
            enterGameName: 'Enter Game name',
            searchLimit: 'Search for at least three characters!',
            history: 'Browsing history',
            congratulations: 'Congratulations to user',
            winning: 'for winning',
            in: 'in',
            artificial: 'Artificial',
            language: 'Language',
            addTopDesktopContent: ' To make it easier for you to use this game platform, do you agree to add it to your desktop safely?',

        },
        // 英语资料界面语言
        profile: {
            favorite: "Favorite",
            history: "Broshing History",
            languages: "Languages",
            nickname: 'Nickname',
            gender: 'Gender',
            confirm: 'Confirm',
            cancer: 'Cancer',
            Logout: 'Logout',
            nameIsNull: 'Empty',
            enterName: 'Enter your name',
            enterGender: 'Enter your gender',
            sort: 'NO.',
            profileClassificationLike: 'Like',
            profileClassificationHistory: 'History',
            profileClassificationGuess: 'Guess you like',
            playMore: 'Play More',
            winMore: 'Win More',
            switchAccount: 'Switch account',
            rankList: "week's hot list",
            email: "Email:",
            setName: 'Set name',
            notSupportChangeName: 'Visitors do not support name changes',
        },
        // 底部操作语言
        bottom: {
            home: 'Home',
            profile: 'Mine',
            noMore: 'No more~',
        },
        // 用户行为
        userAction: {
            favorite: 'Favorite',
            history: 'History',
            played: ' People have played',
            start: 'Click to start the game!',
            cannotPlayGame: 'You cannot play this game',
            installed: "Congratulations, you have installed this software to your desktop"
        },
        // 英语按钮语言
        button: {
            play: 'Play',
            cancer: 'Cancer',
            confirm: 'Confirm',
            addTopDesktop: 'Add to desktop',
        },
        // 登录提示
        loginTip: {
            signInToPlayAllGames: " Sign in to play all games",
            signInNow: 'Sign in now',
            selectAcount: 'Select account to log in',
            signInWithEmail: 'Login with Email account',
            signInWithGoogle: 'Login with Google account',
            signInWithFB: 'Login with Facebook account',
            emailAccountLogin: 'Email account login',
            sendCheckCode: 'Send code',
            enterEmail: 'Enter your email',
            enterCheckCode: 'Enter check code',
            notSupportEmail: 'The mailbox format is incorrect or not supported',
        },
        //  请求结果通知
        axios: {
            signInSuccessful: "Login successful",
            collectSuccess: 'Collect successful',
            cancerCollect: 'Uncollect',
            noData: 'No data',
            replication: 'Successful replication',
            successful: 'successful',
            failed: 'failed',
            noLogin: "You haven't logged in yet",
            choosePhoto: 'Please choose photo first',
            fileToLarge: 'The photo cannot be larger than 1M',
            fileTypeNoSupports: 'The file type is not supported',
            identity: 'Current identity:Tourist',
            accountIsNotExisting: 'Account does not exist',
        }
    }
}

