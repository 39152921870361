<template>
  <div :style="{
    width: pageWidth,
    minHeight: '1850px',
    // height: pageHeight,
    // backgroundColor: backColor,
  }">
    <div class="swiper">
      <Slideshow></Slideshow>
    </div>

    <div class="rankListBonusInfoBox">
      <RankList></RankList>
    </div>

    <!-- <div class="cbox">
      <div class="classifyNavBox">
        <div class="classifyItem" v-for="(item, index) in classifyData" @click="findGameByPageAndClassify(item, index)">
          <div class="iconPosition">
            <img :src="$Host + item.icon" alt="" /> {{ item.name }}
          </div>
        </div>
      </div>

      <div class="searchBox" @click="showSearchBox">
        <img style="width: 90%" :src="$Host + '/images/Search.png'" alt="" />
      </div>
    </div> -->

    <div class="classifyNavBox2">
      <div class="classifyNavBox2Item" v-for="(item, index) in classifyData" v-if="item.name != 'All' && item.name != '全部' && item.name != 'Todo' &&
        item.id != -1" @click="toAllGames(item)">
        <img :src="$Host + item.image" style="width: 100%;" alt="">
      </div>
    </div>

    <div class="haveDataBox" v-if="isHaveData">
      <!--eslint-disable-next-line-->
      <div v-for="cItem in classifyData" :id="'gameClassify' + cItem.id" v-if="
        isShowClassify &&
        cItem.name != 'All' &&
        cItem.name != '全部' &&
        cItem.name != 'Todo'
      ">
        <Classify :classification="cItem"> </Classify>
        <div class="gameBox">
          <div class="gameItem" v-for="hItem in historyGameData" v-if="(cItem.id == -1 && historyGameData.length > 0)">
            <Game :game="hItem"> </Game>
          </div>
          <!--eslint-disable-next-line-->
          <div class="gameItem" v-for="gItem in gameData" v-if="cItem.parentId == gItem.cid">
            <Game :game="gItem"> </Game>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="noDataBox"></div>

    <van-action-sheet v-model="showSearchView" style="background-color: #1c2028">
      <h3 style="color: white; margin-left: 20px">
        {{ $t("common.home.search") }}
      </h3>
      <div class="searchBoxTop">
        <!-- <input type="text" class="searchInput" name="" id="" placeholder="Enter game name"> -->
        <van-search v-model="searchWord" @input="searchHandle" @search="searchHandle" class="searchInput"
          background="none" input-align="center" :placeholder="$t('common.home.enterGameName')" />
        <div class="userAction" @click="toUserActionPage('history')">
          <!-- <van-icon color="white" name="browsing-history" size="22" /> -->
          <img style="width: 22px;" :src="$Host + '/images/visit.png'" alt="">
        </div>
        <div class="userAction" @click="toUserActionPage('favorite')">
          <img style="width: 22px;" :src="$Host + '/images/like.png'" alt="">
        </div>
      </div>
      <p style="text-align: center; font-size: 13px; color: gray">
        {{ $t("common.home.searchLimit") }}
      </p>
      <div class="searchWordHistorybox">
        <!-- <div class="searchWordHistoryboxItem" v-for="item in 10"> -->
        <!--eslint-disable-next-line-->
        <div class="searchWordHistoryboxItem" v-for="item in searchWoldHistory"
          @click="(searchWord = item), findGameByWord()">
          {{ item }}
          <span @click="deleteSearchWord(item)">
            <van-icon name="cross" /></span>
        </div>

        <div class="clearSearchWordHistory" @click="clearHistorySearchWord">
          <van-icon color="#516382" name="delete-o" size="16" />
        </div>
      </div>

      <div class="recentBox" v-if="searchGameData.length > 0">
        <van-icon name="checked" size="22" color="#ccc" style="vertical-align: middle" />
        {{ $t("common.home.result") }}
      </div>
      <div class="gameBox" v-if="searchGameData.length > 0">
        <!--eslint-disable-next-line-->
        <div class="gameItem" v-for="gItem in searchGameData" @click="">
          <Game :game="gItem"> </Game>
        </div>
      </div>

      <div class="recentBox" v-if="
        !(
          this.historyGameData == null ||
          JSON.stringify(this.historyGameData) == '[]'
        )
      ">
        <van-icon name="clock" size="22" color="#ccc" style="vertical-align: middle" />
        {{ $t("common.home.history") }}
      </div>

      <div class="gameBox" v-if="
        !(
          this.historyGameData == null ||
          JSON.stringify(this.historyGameData) == '[]'
        )
      ">
        <!--eslint-disable-next-line-->
        <div class="gameItem" v-for="gItem in historyGameData" @click="">
          <Game :game="gItem"> </Game>
        </div>
      </div>

      <div style="width: 100%; height: 20px"></div>
    </van-action-sheet>

    <div class="gameBoxByClassificationBox" v-if="!isShowClassify">
      <!--eslint-disable-next-line-->
      <div class="gameByClassificationItem" v-for="item in gameData">
        <Game :game="item"> </Game>
      </div>

      <div class="gameItemPlaceOrder"></div>
      <div class="gameItemPlaceOrder"></div>
      <div class="gameItemPlaceOrder"></div>

    </div>

    <div class="homeplaceorder"></div>
  </div>
</template>

<script>
import Slideshow from "../slideshow/Slideshow.vue";
import Classify from "../classification/Classify.vue";
import Game from "../game/Game.vue";
import RankList from "../rankList/RankList.vue";
import { Toast } from "vant";
export default {
  data() {
    return {
      show: false,
      classifyData: [],
      classificationList: [],
      gameData: [],
      gameByClassification: [],
      historyGameData: [],
      searchGameData: [],
      searchWoldHistory: [],

      isShowClassify: true,
      pageHeight: "",
      pageWidth: "",
      backColor: "#15161c",
      showSearchView: false,
      isHaveData: true,
      searchWord: "",
    };
  },
  methods: {


    clearHistorySearchWord() {
      localStorage.removeItem("7topgame.com-search-word-arrs");
      this.searchWoldHistory = [];
    },

    deleteSearchWord(e) {
      for (let i = 0; i < this.searchWoldHistory.length; i++) {
        const element = this.searchWoldHistory[i];
        if (element == e) {
          this.searchWoldHistory.splice(i, 1);
          break;
        }
      }
      localStorage.setItem(
        "7topgame.com-search-word-arrs",
        JSON.stringify(this.searchWoldHistory)
      );
    },

    searchHandle() {
      if (this.searchWord.length >= 3) {
        this.findGameByWord();
      }
    },

    findGameByWord() {
      if (this.searchWord.length < 3) {
        return;
      }

      Toast.loading({
        forbidClick: true,
        loadingType: "spinner",
      });

      setTimeout(() => {
        this.$axios
          .get(
            this.$Host +
            "/client/game/findByBlurGameName?lang=" +
            localStorage.getItem("7topgame.com-user-lang") +
            "&gameName=" +
            this.searchWord
          )
          .then((res) => {
            if (res.data.code == 10041) {
              this.searchGameData = res.data.data;
            }
            Toast.clear();

            let arr = localStorage.getItem("7topgame.com-search-word-arrs");
            if (arr == null || arr.length <= 0) {
              let newArr = [];
              newArr.push(this.searchWord);
              localStorage.setItem(
                "7topgame.com-search-word-arrs",
                JSON.stringify(newArr)
              );
            } else {
              let newArr = JSON.parse(arr);
              newArr.push(this.searchWord);
              localStorage.setItem(
                "7topgame.com-search-word-arrs",
                JSON.stringify(newArr)
              );
            }
            for (let i = 0; i < this.searchWoldHistory.length; i++) {
              const element = this.searchWoldHistory[i];
              if (element == this.searchWord) {
                return;
              }
            }
            this.searchWoldHistory.push(this.searchWord);
          })
          .catch((err) => {
            Toast(this.$t("common.axios.failed"))
          });
      }, 1500);
    },

    showSearchBox() {
      this.showSearchView = true;
      this.historyGameData = JSON.parse(
        localStorage.getItem("7topgame.com-user-history")
      );
      this.getSearchWordHistory();
    },

    getSearchWordHistory() {
      let searchWord = JSON.parse(
        localStorage.getItem("7topgame.com-search-word-arrs")
      );
      if (searchWord == null || searchWord.length <= 0) {
        return;
      }
      this.searchWoldHistory = Array.from(new Set(searchWord));
      localStorage.setItem(
        "7topgame.com-search-word-arrs",
        JSON.stringify(this.searchWoldHistory)
      );
    },

    toUserActionPage(e) {
      this.$store.commit("userActionchange", e);
      this.$router.push("/user/action");
    },

    clickShowClassify(e) {
      if (e.name == "All" || e.name == "全部" || e.name == "Todo") {
        this.findAll();
        this.initializeClassify();
      }
    },

    toAllGames(val) {
      this.$router.push({ path: "/all/games", query: { classification: val.name } });
      this.show = false;
    },

    scrollToItem(e) {
      let ele = document.querySelector("#" + e);

      // let targetLocation = ele.offsetTop
      // let duration = 500
      // let step = Math.floor(targetLocation / duration * 15)
      // let interVal = setInterval(() => {
      //     let scrollTop = targetLocation.scrollTop || document.body.scrollTop
      //     if (scrollTop <= targetLocation || scrollTop <= 0) {
      //         clearInterval(interVal)
      //         return
      //     }
      //     document.documentElement.scrollTop = document.body.scrollTop = scrollTop - step
      // }, 15);

      ele.scrollIntoView({ behavior: "smooth" });
    },

    findGameByPage() {
      let cond = {
        begin: 1,
        size: 10,
        lang: localStorage.getItem("7topgame.com-user-lang") == null
          ? "en"
          : localStorage.getItem("7topgame.com-user-lang"),
      };
      this.$axios
        .post(this.$Host + "/client/game/findByPage", cond)
        .then((res) => {
          if (res.data.code == 10041) {
            this.$store.commit("addHistoryRecords", res.data.data.data);
            this.gameData = res.data.data.data;
            return;
          }
          Toast(this.$t("common.axios.failed"))
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"))
        });
    },

    findAll() {
      let gameList = sessionStorage.getItem("7topgame.com-game-list");
      if (gameList !== null) {
        this.gameData = JSON.parse(gameList);
        setTimeout(() => {
          this.isHaveData = true;
          Toast.clear();
        }, 1000);
        return;
      }
      let lCode = localStorage.getItem("7topgame.com-user-lang");
      if (lCode == null) {
        lCode = "en";
        // localStorage.setItem("7topgame.com-user-lang", "en");
      }
      this.$axios
        .get(this.$Host + "/client/game/findAll?lang=" + lCode)
        .then((res) => {
          if (res.data.code == 10041) {
            sessionStorage.setItem(
              "7topgame.com-game-list",
              JSON.stringify(res.data.data)
            );
            this.gameData = res.data.data;
            setTimeout(() => {
              this.isHaveData = true;
              Toast.clear();
            }, 1000);
            return;
          }
          Toast(this.$t("common.axios.failed"))
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"))
        });
    },

    initializeClassify() {
      let lCode = localStorage.getItem("7topgame.com-user-lang");
      if (lCode == null) {
        lCode = "en";
        // localStorage.setItem("7topgame.com-user-lang", "en");
      }

      let classificationList = JSON.parse(sessionStorage.getItem("7topgame.com-classify"));

      if (classificationList !== null) {
        classificationList.unshift({
          id: -1,
          name: this.$t("common.profile.profileClassificationHistory"),
          icon: '/images/profile-history.png',
          action: 'history',
        })

        this.classifyData = classificationList;
        return;
      }

      this.$axios
        .get(this.$Host + "/common/gameClassify/findByLanguage?l=" + lCode)
        .then((res) => {
          if (res.data.code == 10041) {
            this.classifyData = res.data.data;
            sessionStorage.setItem(
              "7topgame.com-classify",
              JSON.stringify(res.data.data)
            );
            return;
          }
          Toast(this.$t("common.axios.failed"))
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"))
        });
    },

    initalizeData() {
      if (localStorage.getItem("7topgame.com-user-lang") == null) {
        localStorage.setItem("7topgame.com-user-lang", navigator.language);
        location.reload()
      }

      this.historyGameData = JSON.parse(
        localStorage.getItem("7topgame.com-user-history")
      );

      this.initializeClassify();
      this.findAll();
    },

  },
  components: {
    Slideshow,
    Classify,
    Game,
    RankList,
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    this.initalizeData()
    let height = window.innerHeight;
    let width = window.innerWidth;
    this.pageHeight = height + "px";
    this.pageWidth = width + "px";
  },
};
</script>

<style>
.swiper {
  margin-top: 15px;
}

.classifyNavBox {
  margin: 0 auto;
  display: inline-block;
  max-width: 88%;
  height: 32px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  border-radius: 6px;
}

.cbox {
  position: relative;
  margin: 0 auto;
  /* margin-top: 15px; */
  width: 91%;
  height: 34px;
  /* background-color: aqua; */
}

.classifyNavBox::-webkit-scrollbar {
  display: none;
}

.classifyItem {
  display: inline-block;
  position: relative;
  margin-right: 4px;
  width: 60px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  font-size: 10px;
  background-color: #441e71;
  color: white;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  background-image: linear-gradient(to top, #451e72, #231335 81%);
  border-bottom: 2px solid #9b43ff;
}

.searchBox {
  position: relative;
  position: absolute;
  right: 0;
  top: 0;
  width: 10%;
  min-height: 30px;
  color: #9239fb;
  vertical-align: middle;
}

.searchBox img {
  position: absolute;
  bottom: 0;
}

.searchBoxTop {
  margin: 0 auto;
  width: 95%;
  height: 40px;
  /* overflow: hidden; */
  /* background-color: aqua; */
}

.userAction {
  /* display: inline-block; */
  float: right;
  width: 10%;
  min-height: 28px;
  background-color: #17191f;
  text-align: center;
  padding-top: 8px;
  margin-right: 8px;
  border-radius: 6px;
}

.searchInput {
  width: 70%;
  height: 38px;
  border: none;
  margin-left: 5px;
  border-radius: 5px;
  overflow: hidden;
  outline: none;
  vertical-align: middle;
  font-size: 14px;
  text-align: center;
  background-color: black;
}

.searchInput .van-search__content {
  background-color: black;
  height: 40px;
}

.searchBoxTop .van-search {
  display: inline-block;
  padding: 0;
  height: 40px;
}

.searchBoxTop .van-field__control {
  margin: 4px;
  margin-top: 4px;
  color: white;
}

.van-field__left-icon .van-icon,
.van-field__right-icon .van-icon {
  display: block;
  font-size: 18px;
  line-height: inherit;
  margin-top: 5px;
}

.searchBox .van-field__control--center {
  font-size: 14px;
  color: white;
}

.iconPosition {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
  height: 17px;
  line-height: 17px;
  overflow: hidden;
}

.iconPosition img {
  /* vertical-align: middle; */
  width: 18px;
  height: 18px;
}

.classifyItem a {
  display: inline-block;
  background-color: #441e71;
  color: #e1cdf7;
  font-size: 12px;
}

/* .classifyNavBox :first-child {
  font-size: 15px;
} */

/* .classifyItemSelected {
  font-size: 15px;
  
} */

.gameBox {
  margin: 0 auto;
  width: 91%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  border-radius: 8px;
}

.gameBox::-webkit-scrollbar {
  display: none;
}

.gameItem {
  display: inline-block;
  width: 31%;
  margin: 0 6px 0 0;
}

.bottom {
  position: fixed;
  bottom: 0;
  width: 99.5%;
  height: 50px;
  /* background-color: #1b2746; */
}

.homeplaceorder {
  margin-bottom: 50px;
}

.gameBoxByClassificationBox {
  display: flex;
  flex-wrap: wrap;
  /* 允许换行 */
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 91%;
}

.gameItemPlaceOrder {
  flex-basis: calc(100% / 3.3);
  margin: 5px auto;
}

.gameByClassificationItem {
  flex-basis: calc(100% / 3.3);
  margin: 5px auto;
  border-radius: 10px;
  /* overflow: hidden; */
}

.searchWordHistorybox {
  position: relative;
  padding-top: 1px;
  margin: 0 auto;
  width: 95%;
  height: 70px;
  overflow: hidden;
}

.searchWordHistoryboxItem {
  float: left;
  margin: 5px;
  min-width: 15%;
  max-width: 25%;
  height: 25px;
  border-radius: 9px;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  color: #ccc;
  background-color: #171a1f;
}

.clearSearchWordHistory {
  position: absolute;
  right: 5px;
  /* top: 7px; */
}

.recentBox {
  margin: 10px auto;
  width: 90%;
  height: 25px;
  line-height: 25px;
  color: white;
}

.recentGameBox {
  margin: 0 auto;
  width: 95%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.recentGameItemBox {
  float: left;
  margin: 0 auto;
  margin-left: 10px;
}

.noDataBox {
  margin: 0 auto;
  width: 100%;
  min-height: 800px;
  background: none;
}

.rankListBonusInfoBox {
  position: relative;
  margin: 10px auto;
  width: 10%;
  height: 31px;
  line-height: 31px;
  overflow: hidden;
  background-image: url("../../assets/rankListImg.png");
  background-size: cover;
  background-position: center;
}

.classifyNavBox2 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 3px;
  width: 94%;
  /* min-height: 140px; */
  border-radius: 8px;
}

.classifyNavBox2 .classifyNavBox2Item {
  flex-basis: calc(100% / 3.3);
  margin: 0 auto;
  margin-top: 10px;
  max-height: 61px;
  border-radius: 8px;
}
</style>
