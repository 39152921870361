<template>
  <div>

    <div v-if="isHome">
      <nav class="navi">
        <div class="naviBox">
          <div class="logoBox" @click="$router.push('/')">
            <!-- <img style="width: 28px; margin: 0;margin-right: 5px;" :src="$Host + '/images/logo.png'" alt="" /> -->
            <img src="../assets/logoName.png" alt="" />
          </div>
          <div class="asideMenu" @click="showPopup">
            <!-- <van-icon name="bars" color="#b7b8b9" style="font-weight: bold" /> -->
            <img style="width: 22px;margin-top: 3px;" :src="$Host + '/images/menu.png'" alt="">
          </div>
          <div class="signupBox">
            <van-button size="small" color="#d82ec9"
              style="width: 47%;font-size: 12px;margin-right: 7px; border-radius: 7px" type="default">
              {{ $t("common.home.artificial") }}</van-button>
            <van-button size="small" type="primary" style="width: 47%; font-size: 12px; border-radius: 7px"
              @click="showLanguage = true" color="#4add01">{{ $t("common.home.language") }}
            </van-button>
          </div>
        </div>
      </nav>
    </div>

    <div v-else>
      <nav class="navi2">
        <div class="backImg" @click="back">
          <img style="width: 100%;" src="../assets/back.png" alt="">
        </div>
        <div class="backText">
          {{ queryParams }}
        </div>
      </nav>
    </div>

    <van-popup v-model="show" position="left"
      style="background-color: #20262d;border-top-right-radius: 20px;border-bottom-right-radius: 20px;"
      :style="{ width: '45%', height: '100%' }">
      <div class="eixtAsideMenu" @click="show = false">
        <img style="width: 100%;" src="../assets/exit.png" alt="">
      </div>
      <div class="asideMenuTopBox">
        <div class="logoBoxes">
          <img style="width: 110px;height: 28px;" src="../assets/logoName.png" alt="" />
        </div>
      </div>

      <div class="actionSheet">
        <div class="addToDesktop" @click="showInstallTip(true)">
          <img src="../assets/addDesktop.png" alt="">
        </div>
        <div class="actionSheetItem" v-for="item in classifyData" v-if="
          item.name != 'All' && item.name != '全部' && item.name != 'Todo'
        " @click="toAllGames(item.name)">
          <img :src="$Host + item.image" alt="" />
        </div>
      </div>
    </van-popup>

    <van-action-sheet style="background-color: #232631; color: white" title="Switch language" v-model="showLanguage">
      <div class="languageContent">
        <div class="languageItem" @click="changeLanguage('en')">
          English
          <div class="flag">
            <img src="../assets/en.jpg" alt="" />
          </div>
        </div>

        <div class="languageItem" @click="changeLanguage('zh-CN')">
          中文（简体）
          <div class="flag">
            <img src="../assets/zh-CN.jpg" alt="" />
          </div>
        </div>

        <div class="languageItem" @click="changeLanguage('pt')">
          língua portuguesa
          <div class="flag">
            <img src="../assets/portugal.jpg" alt="" />
          </div>
        </div>
      </div>
    </van-action-sheet>

    <!-- 添加到桌面-->
    <van-dialog v-model="$store.state.isInstallApp" :show-confirm-button="false"
      style="width: 91%;;background-color: #23262f;">
      <div class="addToDesktopBox">
        <div class="addToDesktopImg">
          <img src="../assets/addToDesktop.png" style="width: 100%;" alt="">
          <span class="siteName">7TopGame</span>
          <div class="appNameLogo">
            <img src="../assets/photo.png" style="width: 100%;" alt="">
          </div>
        </div>
        <div class="addDesktopSentence">
          {{ $t('common.home.addTopDesktopContent') }}
        </div>
        <div class="addToDesktopBoxBottom">
          <van-button size="small" color="#6e6e6e" @click="showInstallTip(false)"
            style="width: 40%;font-size: 12px;margin-right: 15px; border-radius: 7px" type="default">
            {{ $t("common.button.cancer") }}
          </van-button>
          <van-button size="small" @click="installApp" class="installAppBtn"
            style="width: 40%;font-size: 12px; border-radius: 7px;color: white;background-image: linear-gradient(to right, #FF48EF, #C000F4 100%);border: none;"
            type="default">
            {{ $t("common.button.addTopDesktop") }}
          </van-button>
        </div>
      </div>
    </van-dialog>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { Toast } from "vant";
export default {
  mounted() {
    let c = sessionStorage.getItem("7topgame.com-classify");
    this.classifyData = JSON.parse(c);
    this.watchRouteChangePath()
  },
  data() {
    return {
      show: false,
      isLogin: false,
      classifyData: [],
      showLanguage: false,
      isHome: true,
      queryParams: '',
      addToDesktopDialog: false,
      installAppObj: this.$store.state.installAppObject
    };
  },

  watch: {
    $route: function (to, from) {
      this.watchRouteChangePath()
    },
  },


  computed: {
    ...mapState({
      count: (state) => state.isLoginActionSheet,
    }),
  },

  methods: {

    installApp() {
      this.$store.commit("installApp", this);
      if (this.$store.state.installAppObject == null) {
        this.showInstallTip(false)
      }
    },

    showInstallTip(e) {
      this.$store.commit("showInstallTip", e);

      // setTimeout(() => {
      //   this.showInstallTip(true)
      // }, 5000)
    },

    watchRouteChangePath() {
      if (this.$route.path.includes("/profile") || this.$route.path.includes("/home")) {
        this.isHome = true
      } else {
        let queryParams = this.$route.query.classification
        if (queryParams !== undefined) {
          this.queryParams = queryParams
        }
        this.isHome = false
      }
    },

    back() {
      this.$router.back()
    },

    findRankListByLang() {
      let l = localStorage.getItem("7topgame.com-user-lang")
      this.$axios
        .get(this.$Host + "/client/rankList/getRankLists?lang=" + l)
        .then((res) => {
          if (res.data.code == 10041) {
            this.rankListData = res.data.data;
            sessionStorage.setItem(
              "7topgame.com-rankList",
              JSON.stringify(res.data.data)
            );
            return;
          }
          Toast(this.$t("common.axios.failed"))
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"))
        });
    },

    changeLanguage(e) {
      localStorage.setItem("7topgame.com-user-lang", e);
      this.showLanguage = false;
      localStorage.removeItem("7topgame.com-user-collect");
      localStorage.removeItem("7topgame.com-user-history");
      sessionStorage.removeItem("7topgame.com-classify");
      sessionStorage.removeItem("7topgame.com-game-list");
      sessionStorage.removeItem("7topgame.com-rankList");
      sessionStorage.removeItem("7topgame.com-profile-rankList");
      this.findRankListByLang()
      location.reload();
    },

    selectLanguage(item) {
      this.showLanguage = false;
      Toast(item.name);
    },

    showPopup() {
      this.show = true;
      this.initializeClassify();
    },

    async initializeClassify() {
      let localClassification = JSON.parse(sessionStorage.getItem('7topgame.com-classify'))
      if (localClassification == null) {
        let classificationRequestResult = await this.getClassification()
        localClassification = classificationRequestResult.data.data;
        sessionStorage.setItem(
          "7topgame.com-classify",
          JSON.stringify(classificationRequestResult.data.data)
        );
      }
      for (let i = 0; i < localClassification.length; i++) {
        const classification = localClassification[i];
        let index = classification.icon.lastIndexOf(".");
        let filename = classification.icon.substring(0, index)
        let filetype = classification.icon.substring(index, classification.icon.length)
        filename = filename + '2'
        localClassification[i].icon = filename + filetype
      }
      this.classifyData = localClassification;
    },

    getClassification() {
      let lCode = localStorage.getItem('7topgame.com-user-lang')
      return this.$axios.get(this.$Host + "/common/gameClassify/findByLanguage?l=" + lCode)
    },

    toAllGames(val) {
      this.$router.push({ path: "/all/games", query: { classification: val } });
      this.show = false;
    },
  },

  computed: {
    ...mapState({
      tags: (state) => state.tagList,
    }),
  },
};
</script>

<style>
body {
  padding: 0;
  margin: 0;
}

.navi {
  position: relative;
  width: 100%;
  height: 56px;
  padding-top: 1px;
  box-shadow: 0 0 10px 0 rgb(26, 25, 25);
  background-image: linear-gradient(to left, #a0227f, #20262d 50%);
}

.navi2 {
  position: relative;
  width: 100%;
  height: 56px;
  padding-top: 1px;
  box-shadow: 0 0 10px 0 rgb(26, 25, 25);
  background-image: linear-gradient(to left, #a0227f, #20262d 100%);
}

.naviBox {
  position: relative;
  margin: 0 auto;
  margin-top: 12px;
  width: 91%;
  height: 35px;
}

.asideMenu {
  position: absolute;
  top: 5px;
  left: 0%;
  width: 7%;
  height: 40%;
  /* background-color: red; */
  /* display: inline-block;
   margin: 10px 0 0 0 ;  */
}

.logoBox {
  position: relative;
  margin-left: 8%;
  width: 102px;
  text-align: right;
}

.logoBox img {
  float: left;
  margin-top: 8px;
  margin-left: 3px;
  width: 102px;
}


.asideMenu i {
  font-size: 20px;
  color: #516382;
}

.signupBox {
  float: right;
  margin-top: 3px;
  width: 46%;
  height: 33px;
  border-radius: 5px;
}

.signupBox button {
  flex: 1;
}

.signupBox button:focus {
  outline: none;
  box-shadow: none;
}

.asideMenuTopBox {
  display: flex;
  margin: 50px 0px 0 0px;
  margin-top: 50px;
  margin-bottom: 0px;
  width: 85%;
  border-radius: 2px;
  /* border-bottom: 3px solid #913feb;
  background-image: linear-gradient(to top, #462278, #080d1b); */
}

.logoBoxes {
  display: flex;
  margin: 0 auto;
  margin-left: 20px;
  width: 80%;
  margin-top: 2px;
  height: 40px;
  /* background-color: aqua; */
}

.logoName {
  flex: 1.5;
  color: #b7cbec;
  font-size: 22px;
  text-align: center;
  line-height: 40px;
}

.logoIcon {
  flex: 0.5;
}

.logoIcon img {
  width: 36px;
}

/* .asideMenuTopBox div {
  flex: 1;
  margin: 0 5px;
  text-align: center;
  color: #92a2c0;
  font-size: 12px;
  border-radius: 5px;
  background-color: #22242b;
} */

/* .asideMenuTopBox div div {
  font-size: 18px;
  margin-top: 4px;
} */

.asideMenuTopBox .asideMenuTopBoxItemSelected {
  flex: 1;
  margin: 0 0px;
  text-align: center;
  color: #92a2c0;
  font-size: 12px;
  border-radius: 5px;
  background-color: #30323b;
}

.asideMenuTopBox .asideMenuTopBoxItemSelected div {
  font-size: 18px;
  margin-top: 4px;
  background-color: #30323b;
}

.actionSheet {
  margin: 0 auto;
  margin-left: 20px;
  width: 86%;
  border-radius: 2px;
}

.actionSheet div{
    margin: 3% 0 0 0;
      width: 82%;
      /* min-height: 67.5px; */
      border-radius: 7px;
}

/* .actionSheetItem {
  margin: 3% 0 0 0;
  width: 82%;
  border-radius: 7px;
} */

.actionSheetItem img {
  width: 100%;
}

/* .addToDesktop {
  margin: 3% 0 0 0;
  width: 82%;
  height: 43px;
  border-radius: 7px;
} */

.addToDesktop img {
  width: 100%;
}

.backImg {
  position: absolute;
  top: 25%;
  left: 5%;
  width: 25px;
  height: 25px;
}

.backText {
  position: absolute;
  top: 0;
  left: 15%;
  width: 80%;
  height: 56px;
  line-height: 58px;
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.eixtAsideMenu {
  position: absolute;
  top: 3%;
  right: 8%;
  width: 12%;
  height: 3%;
}

.addToDesktopBox {
  position: relative;
  margin: 0 auto;
  padding-top: 1px;
  width: 100%;
  height: 100%;
  min-height: 285px;
}

.addToDesktopImg {
  position: relative;
  margin: 15px auto;
  width: 90%;
}

.appNameLogo {
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: -40px;
  width: 80px;
  height: 80px;
}

.addDesktopSentence {
  margin: 0 auto;
  width: 91%;
  color: white;
  font-size: 14px;
  text-align: center;
}

.siteName {
  position: absolute;
  bottom: 15px;
  left: 50%;
  margin-left: -40px;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.addToDesktopBoxBottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  text-align: center;
}
</style>
