<template>
    <div>
        <div class="classifyBox1">
            <div class="leftArea1" style="
          height: 25px;
          display: flex;
          align-items: left;
          justify-content: left;
          color: #D4AFFC;
          font-weight: bold;
        ">
                <!-- <van-icon name="fire" /> -->
                <img class="iconItem1" :src="$Host + '/images' + classification.icon" alt="" />
                {{ classification.name }}
            </div>
            <div class="rightArea1">
                <div class="moreBtn1" @click="toAllGames()">
                    <img class="moreBtn1Play" src="../../assets/moreBtnPlay.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        classification: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {};
    },
    methods: {
        toUserActionPage(e) {
            if (e == "favorite") {
                if (JSON.stringify(this.userInfo) == "{}") {
                    Toast(this.$t("common.axios.noLogin"))
                    setTimeout(() => {
                        this.$router.push("/login");
                    }, 1500);
                    return;
                }
            }
            this.$store.commit("userActionchange", e);
            this.$router.push("/user/action");
        },

        toAllGames() {
            this.$router.push({
                path: "/all/games",
                query: { classification: this.classification.name },
            });
        },
    },
    mounted() {

    },
};
</script>

<style>
.classifyBox1 {
    display: flex;
    /* justify-content: center; */
    margin: 14px auto;
    margin-top: 7px;
    width: 91%;
    /* height: 35px; */
    /* border: 1px solid #ccc; */
}

.classifyBox1 div {
    /* margin: 0 17px; */
    /* line-height: 35px; */
    /* text-indent: 1rem; */
    color: white;
    /* background-color: pink; */
}

.rightArea1 {
    flex: 0.7;
    font-size: 14px;
    text-align: right;
}

.leftArea1 {
    flex: 1.3;
    line-height: 25px;
}

.moreBtn1 {
    /* position: relative; */
    float: right;
    margin-top: 1px;
    width: 56px;
    height: 28px;
    line-height: 30px;
    font-size: 11px;
    background-color: #292f35;
    opacity: 0.9;
    color: #acaeb1;
    border: none;
    text-align: right;
    border-radius: 4px;
}

.moreBtn1Play {
    width: 100%;
}

/* .moreBtn1:after {
    content: '';
    position: absolute;
    right: 15%;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-image: url('../../assets/moreBtn1Play.png');
    background-size: cover;
} */

.iconItem1 {
    float: left;
    margin-right: 10px;
    /* margin-top: 2px; */
    width: 25px;
    /* height: 25px; */
}
</style>
