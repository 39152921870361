// 葡萄牙语言包

export default {
    common: {
        // 登录界面语言
        login: {
            bannerWord: 'Bem-vindo ao login',
            account: 'Email',
            verifyCode: 'Verificar código',
            sendCode: "Enviar código",
            password: "Senha",
            signInWithPassword: 'Entrar com senha',
            signInWithEmail: 'Entrar com e-mail',
            signIn: 'Entrar',
            signInWithTourist: 'Cadastre-se com turista',
            signInWithGoogle: 'Entrar com google',
            inputTip: 'Digite seu alcunha (opcional)'
        },
        // 注册界面语言
        register: {
            bannerWord: 'Bem-vindo a se inscrever',
            account: 'Email',
            verifyCode: 'Verificar código',
            sendCode: "Enviar código",
            password: "Senha",
            signInWithPassword: 'Entrar com senha',
            signInWithEmail: 'Entrar com e-mail',
            signUp: 'Entrar',
        },
        // 主界面语言
        home: {
            signIn: 'Entrar',
            signUp: 'Inscrição',
            recommend: 'Recomendar',
            more: 'Mais',
            hot: 'Quente',
            new: 'NOVO',
            search: 'Busca',
            result: 'Resultados Da Busca',
            enterGameName: 'Digite o nome do jogo',
            searchLimit: 'Procurar pelo menos três caracteres!',
            history: 'O histórico de navegação',
            congratulations: 'Parabéns ao usuário',
            in: 'em',
            artificial: 'artificial',
            language: 'línguas',
            winning: 'ganhou',
            addTopDesktopContent: 'Para facilitar o uso desta plataforma de jogos, você concorda em adicioná-la à sua área de trabalho com segurança?',
        },
        // 资料界面语言
        profile: {
            favorite: "Favorito",
            history: "O histórico de navegação",
            languages: "Idiomas",
            nickname: 'Apelido',
            gender: 'Gênero',
            confirm: 'Confirmar',
            cancer: 'Câncer',
            nameIsNull: 'Vazio',
            Logout: 'Logoff',
            enterName: 'Digite seu nome',
            enterGender: 'Digite o sexo',
            sort: 'NO.',
            profileClassificationLike: 'Gosta de',
            profileClassificationHistory: 'A história',
            profileClassificationGuess: 'Acho que você gosta',
            playMore: 'jogar mais',
            winMore: 'ganhe mais',
            switchAccount: 'Mudar de conta',
            rankList: "Hot semanal lista",
            email: "Email:",
            setName: 'Definir um nome',
            notSupportChangeName: 'Mudança de nome não suportada por turistas',
        },
        // 底部操作语言
        bottom: {
            home: 'Casa',
            profile: 'Mina',
            noMore: 'Não há mais~',
        },
        userAction: {
            favorite: "Favorito",
            history: "O histórico de navegação",
            played: ' Pessoa jogou',
            start: 'Clique para começar o jogo!',
            cannotPlayGame: 'Você não pode jogar este jogo',
            installed: "Parabéns, você instalou este software no desktop"
        },
        button: {
            play: 'Brincar',
            cancer: 'cancelar',
            confirm: 'homologação',
            addTopDesktop: 'Adicionar ao desktop',
        },
        loginTip: {
            signInToPlayAllGames: "Entre para abrir todos os jogos",
            signInNow: 'login now',
            selectAcount: 'Escolha uma conta login',
            signInWithEmail: 'Login por email',
            signInWithGoogle: 'Entrar com google',
            signInWithFB: 'Entrar via facebook',
            emailAccountLogin: 'Login por email',
            sendCheckCode: 'Enviar',
            enterEmail: 'Digite seu e-mail',
            enterCheckCode: 'verificação',
            notSupportEmail: 'Formato de caixa de correio não suportado',
        },
        //  请求结果通知
        axios: {
            signInSuccessful: "Login com sucesso",
            collectSuccess: 'Colecção sucesso',
            cancerCollect: 'Cancelar a coleção',
            noData: 'Dados não disponíveis',
            replication: 'Replicar com sucesso',
            successful: 'Operação com sucesso',
            failed: 'fracasso',
            noLogin: "Você ainda não está logado",
            choosePhoto: 'Por favor, selecione a imagem primeiro',
            fileToLarge: 'A imagem não pode ser maior que 1M',
            fileTypeNoSupports: 'Tipo de arquivo não suportado',
            identity: 'A identidade atual:Turista',
            accountIsNotExisting: 'Conta não existe',
        }

    }
}

