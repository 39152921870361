<template>
  <div :style="{
    width: pageWidth,
    minHeight: '1300px',
    // backgroundColor: backColor
  }">
    <div class="rankListBonusInfoBox">
      <RankList />
    </div>

    <div class="persionalInfo">
      <img style="width: 100%" src="../../assets/avatar.png" alt="" />
      <div class="playMoreWinMore">
        <span style="color: #f82ae6"> {{ $t("common.profile.playMore") }}</span>
        {{ $t("common.profile.winMore") }}
      </div>
      <div class="idTypeShow" v-if="userInfo.email">
        {{ $t("common.profile.email") }}
        <span style="font-weight: bold">{{ emailNameFormattern }}</span>
      </div>
      <div class="usernameShow" @click="changeNicknameDialog = true">
        <span style="font-weight: bold">{{
          userInfo.name == undefined || userInfo.name.length <= 0 ? $t("common.profile.setName") : userInfo.name
            }}</span>
      </div>
      <div class="switchAccountButtion" @click="showLoginOptionActionSheet">
        {{ $t("common.profile.switchAccount") }}
      </div>
    </div>

    <div class="rankListHotAd">
      <img style="vertical-align: middle;width: 25px;" src="../../assets/a-week-hot.png" alt="" />
      <span style="margin-left: 7px"> {{ $t("common.profile.rankList") }}</span>
    </div>

    <div class="rankListByGameBox" v-for="(item, index) in rankListData" :key="item.id">
      <div class="rankListByGameItemLeft">
        <div class="rankListGameItem">
          <div v-if="index == 0" class="rankListGameItemHotIcon">HOT.1</div>
          <div v-if="index == 1" class="rankListGameItemHotIcon">HOT.2</div>
          <div v-if="index == 2" class="rankListGameItemHotIcon">HOT.3</div>
          <Game :game="item"></Game>
        </div>
      </div>
      <div class="rankListByGameItemRight">
        <div class="rankListByGameItemBonusDetail">
          <RankListProfile :rankList="item.rankLists"></RankListProfile>
        </div>
      </div>
    </div>

    <div v-for="cItem in classificationList">
      <ProfileClassification :classification="cItem"> </ProfileClassification>
      <div class="profileGameBox">
        <div class="profileGameItem" v-for="(gItem, index) in favoriteGameData" v-bind:key="index" v-if="cItem.id == 1">
          <Game :game="gItem"></Game>
        </div>
      </div>
      <div class="profileGameBox">
        <div class="profileGameItem" v-for="(gItem, index) in historyGameData" v-bind:key="index" v-if="cItem.id == 2">
          <Game :game="gItem"></Game>
        </div>
      </div>
      <div class="profileGameBox">
        <div class="profileGameItem" v-for="(gItem, index) in recommandGamesData" v-bind:key="index"
          v-if="cItem.id == 3">
          <Game :game="gItem"></Game>
        </div>
      </div>
    </div>

    <!-- 更改头像 -->
    <van-dialog v-model="changePhotoDialog" :show-confirm-button="false" style="background-color: #162039">
      <van-form>
        <div class="changePhotoBox">
          <van-uploader :after-read="afterRead" v-model="avatarObj" multiple :max-count="1" />
        </div>
        <div class="usernameAndGenderInfo">
          <van-field v-model="userInfo.name" :label="$t('common.profile.nickname')" style="background-color: #162039"
            :placeholder="$t('common.profile.enterName')" />
          <van-field v-model="userInfo.gender" :label="$t('common.profile.gender')" style="background-color: #162039"
            :placeholder="$t('common.profile.enterGender')" />
        </div>
        <div class="bottomBtn">
          <van-button style="
              outline: none;
              border: none;
              color: white;
              background-color: #162039;
            " size="" type="" @click="cancer">{{ $t("common.profile.cancer") }}</van-button>
          <van-button style="
              outline: none;
              border: none;
              background-color: #162039;
              color: white;
            " size="" type="" @click="submit">{{ $t("common.profile.confirm") }}</van-button>
        </div>
      </van-form>
    </van-dialog>

    <!-- 更改名字 -->
    <van-dialog v-model="changeNicknameDialog" :show-confirm-button="false" style="background-color: #162039">
      <div class="changeNicknameBox">
        <div class="changeNicknameBoxTop"></div>
        <div class="changeNicknameBoxCenter">
          {{ $t("common.profile.nickname") }} :
          <input type="text" class="changeNicknameIpu" v-model="userInfo.name" />
        </div>
        <div class="changeNicknameBoxBottom">
          <van-button size="small" color="#3c3d3f" @click="changeNicknameDialog = false" style="
              width: 110px;
              font-size: 12px;
              margin-right: 15px;
              border-radius: 7px;
            " type="default">
            {{ $t("common.profile.cancer") }}
          </van-button>
          <van-button size="small" @click="changNickname" style="
            width: 110px;
              font-size: 12px;
              border-radius: 7px;
              color: white;
              border: none;
          " class="changeNicknameBtn" type="default">
            {{ $t("common.profile.confirm") }}
          </van-button>
        </div>
      </div>
    </van-dialog>

    <van-action-sheet style="background-color: #232631; color: white" title="Switch language" v-model="showLanguage">
      <div class="languageContent">
        <div class="languageItem" @click="changeLanguage('en')">
          English
          <div class="flag">
            <img src="../../assets/en.jpg" alt="" />
          </div>
        </div>

        <div class="languageItem" @click="changeLanguage('zh-CN')">
          中文（简体）
          <div class="flag">
            <img src="../../assets/zh-CN.jpg" alt="" />
          </div>
        </div>

        <div class="languageItem" @click="changeLanguage('pt')">
          língua portuguesa
          <div class="flag">
            <img src="../../assets/portugal.jpg" alt="" />
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import request from "../../request/request";
import RankList from "../rankList/RankList.vue";
import RankListProfile from "../rankList/RankListProfile.vue";
import ProfileClassification from "../classification/ProfileClassification.vue";
import Game from "../game/Game.vue";
import { Toast } from "vant";
export default {
  data() {
    return {
      imgurl: request.IMGURL,
      userInfo: {},
      avatarObj: [],
      changePhotoDialog: false,
      pageHeight: "",
      pageWidth: "",
      showLanguage: false,
      backColor: "#15161c",
      languages: [
        { name: "English", value: "en" },
        { name: "中文（简体）", value: "zh-CN" },
        { name: "língua portuguesa", value: "pt" },
      ],
      flagPath: [
        {
          en: "/src/static/en.jpg",
        },
        {
          zh: "/src/static/zh-CN.jpg",
        },
        {
          pt: "/src/static/pt.jpg",
        },
      ],
      rankListData: [],
      classificationList: [
        {
          id: 1,
          name: this.$t("common.profile.profileClassificationLike"),
          icon: "/profile-like.png",
          action: "favorite",
          parentId: -1,
        },
        {
          id: 2,
          name: this.$t("common.profile.profileClassificationHistory"),
          icon: "/profile-history.png",
          action: "history",
          parentId: -2,
        },
        {
          id: 3,
          name: this.$t("common.profile.profileClassificationGuess"),
          icon: "/guess-you-like.png",
          action: "guess",
          parentId: -3,
        },
      ],
      favoriteGameData: [],
      historyGameData: [],
      recommandGamesData: [],
      gameList: [],
      changeNicknameDialog: false,
      emailNameFormattern: ''
    };
  },
  components: {
    RankList,
    RankListProfile,
    ProfileClassification,
    Game,
  },
  methods: {
    showLoginOptionActionSheet() {
      this.$store.commit("showLoginActionSheet", true);
    },

    changNickname() {
      if (this.userInfo.isTourist) {
        Toast(this.$t("common.profile.notSupportChangeName"));
        return
      }
      this.$axios
        .post(this.$Host + "/client/user/updateByName", this.userInfo)
        .then((res) => {
          if (res.data.code == 10031) {
            this.userInfo.name = res.data.data;
            localStorage.setItem(
              "7topgame.com-user-info",
              JSON.stringify(this.userInfo)
            );
            this.changeNicknameDialog = false;
            return;
          }
          Toast(this.$t("common.axios.failed"));
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"));
        });
    },

    randomRecommandGames() {
      let gameData = JSON.parse(
        sessionStorage.getItem("7topgame.com-game-list")
      );

      if (gameData == null) {
        setTimeout(() => {
          this.$router.push("/");
        }, 1500);
      }

      let recommandGameIdArr = this.generateRandomNumbers(
        20,
        gameData.length + 1
      );

      let gameList = [];

      for (let i = 0; i < gameData.length; i++) {
        for (let j = 0; j < recommandGameIdArr.length; j++) {
          if (gameData[i].id == recommandGameIdArr[j]) {
            gameList.push(gameData[i]);
            break;
          }
        }
      }

      this.recommandGamesData = gameList;

      localStorage.setItem(
        "7topgame.com-random-recommand-games",
        JSON.stringify(gameList)
      );
    },

    generateRandomNumbers(count, max) {
      const randomNumbers = [];
      for (let i = 0; i < count; i++) {
        randomNumbers.push(Math.floor(Math.random() * max + 1));
      }
      return Array.from(new Set(randomNumbers));
    },

    filterFavoriteGame() {
      if (JSON.stringify(this.userInfo) == "{}") {
        Toast(this.$t("common.axios.noLogin"));
        setTimeout(() => {
          // this.$router.push("/login");
        }, 1500);
        return;
      }
      let gameData = JSON.parse(
        sessionStorage.getItem("7topgame.com-game-list")
      );

      if (gameData == null) {
        setTimeout(() => {
          this.$router.push("/");
        }, 1500);
      } else {
        this.gameList = gameData;
      }

      let userCollectRequestResult = this.getUserFavorite();
      let userCollect = userCollectRequestResult
        .then((res) => {
          if (res.data.code == 10041) {
            if (res.data.data.length <= 0) {
              return;
            }
            let favoriteGames = [];
            userCollect = res.data.data;
            for (let i = 0; i < gameData.length; i++) {
              const game = gameData[i];
              for (let j = 0; j < userCollect.length; j++) {
                const collect = userCollect[j];
                if (game.id == collect.gameId) {
                  favoriteGames.push(game);
                }
              }
            }
            this.favoriteGameData = favoriteGames;
            localStorage.setItem(
              "7topgame.com-user-collect",
              JSON.stringify(userCollect)
            );
          }
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"));
        });
    },

    async getUserFavorite() {
      let result = await this.$axios.get(
        this.$Host + "/client/tourist/collect/findByTid?tid=" + this.userInfo.id
      );
      return result;
    },

    copyId(e) {
      if (e == undefined) {
        Toast(this.$t("common.axios.noData"));
        return;
      }
      navigator.clipboard
        .writeText(e)
        .then((clipText) => {
          Toast(this.$t("common.axios.replication"));
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"));
        });
    },

    findRankListByLang() {
      let l = localStorage.getItem("7topgame.com-user-lang");
      this.$axios
        .get(this.$Host + "/client/rankList/getRankLists?lang=" + l)
        .then((res) => {
          if (res.data.code == 10041) {
            this.rankListData = res.data.data;
            sessionStorage.setItem(
              "7topgame.com-profile-rankList",
              JSON.stringify(res.data.data)
            );
            return;
          }
          Toast(this.$t("common.axios.failed"));
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"));
        });
    },

    changeLanguage(e) {
      localStorage.setItem("7topgame.com-user-lang", e);
      this.showLanguage = false;
      sessionStorage.removeItem("7topgame.com-classify");
      sessionStorage.removeItem("7topgame.com-game-list");
      localStorage.removeItem("7topgame.com-user-collect");
      localStorage.removeItem("7topgame.com-user-history");
      localStorage.removeItem("7topgame.com-rankList");
      localStorage.removeItem("7topgame.com-profile-rankList");
      this.findRankListByLang();
      location.reload();
    },

    selectLanguage(item) {
      this.showLanguage = false;
      Toast(item.name);
    },

    showChangePhotoDialog() {
      if (JSON.stringify(this.userInfo) == "{}") {
        Toast(this.$t("common.axios.noLogin"));
        setTimeout(() => {
          this.$router.push("/login");
        }, 1500);
        return;
      }
      this.changePhotoDialog = true;
    },

    cancer() {
      this.changePhotoDialog = false;
    },

    changePage(page) {
      this.$router.push(page);
    },

    afterRead(file) { },

    submit() {
      if (JSON.stringify(this.userInfo) == "{}") {
        Toast(this.$t("common.axios.noLogin"));
        setTimeout(() => {
          this.$router.push("/login");
        }, 1500);
        return;
      }
      if (this.avatarObj.length <= 0) {
        Toast(this.$t("common.axios.choosePhoto"));
        return;
      }

      let fileInfo = this.avatarObj[0].file;

      if (fileInfo.size / 1024 > 1024) {
        Toast(this.$t("common.axios.fileToLarge"));
        return;
      }

      let fileType = fileInfo.name.substring(
        fileInfo.name.lastIndexOf("."),
        fileInfo.name.length
      );

      if (fileType !== ".jpg" && fileType !== ".png" && fileType !== ".jpeg") {
        Toast(this.$t("common.axios.fileTypeNoSupports"));
        return;
      }

      let formData = new FormData();
      formData.append("file", fileInfo);
      formData.append("id", this.userInfo.id);
      formData.append("name", this.userInfo.name);
      formData.append("gender", this.userInfo.gender);
      formData.append("requestUrl", request.HOST + request.IMGURL);

      this.$axios
        .post(this.$Host + "/client/tourist/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        .then((res) => {
          if (res.data.code == 10011) {
            this.changePhotoDialog = false;
            localStorage.setItem(
              "7topgame.com-user-info",
              JSON.stringify(res.data.data)
            );
            this.userInfo = res.data.data;
            Toast(this.$t("common.axios.successful"));
            return;
          }
          Toast(this.$t("common.axios.failed"));
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"));
        });
    },

    emialFormattern() {
      if (this.userInfo.email != null) {
        let index = this.userInfo.email.lastIndexOf('@')
        let emialName = this.userInfo.email.substring(0, index)
        let prefixName = emialName.substring(0, 3)
        let suffix = emialName.substring(index - 3, index)
        this.emailNameFormattern = prefixName + '...' + suffix
      }
    },

    setLocalClassification() {
      let classificationList = JSON.parse(sessionStorage.getItem('7topgame.com-classify'))
      let newArr = classificationList.concat(this.classificationList)
      sessionStorage.setItem('7topgame.com-classify', JSON.stringify(newArr))
    },

  },
  mounted() {
    // this.setLocalClassification()
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    this.userInfo = JSON.parse(localStorage.getItem("7topgame.com-user-info"));
    if (this.userInfo == null) {
      localStorage.setItem("7topgame.com-user-info", JSON.stringify({}));
      this.userInfo = JSON.parse(
        localStorage.getItem("7topgame.com-user-info")
      );
      // setTimeout(() => {
      //   this.$router.push("/login");
      // }, 1000);
    }
    this.emialFormattern()
    let rankListData = JSON.parse(
      sessionStorage.getItem("7topgame.com-profile-rankList")
    );
    if (rankListData == null) {
      this.findRankListByLang();
    }
    this.rankListData = rankListData;

    this.filterFavoriteGame();

    this.historyGameData = JSON.parse(
      localStorage.getItem("7topgame.com-user-history")
    );

    this.randomRecommandGames();

    let height = window.innerHeight;
    let width = window.innerWidth;
    this.pageWidth = width + "px";
  },
};
</script>

<style>
.bottomBox {
  display: flex;
}

.bottomBoxItem {
  flex: 1;
  margin-top: 8px;
  text-align: center;
  color: #ffffff;
}

.persionalInfo {
  position: relative;
  margin: 0 auto;
  margin-top: 12px;
  padding-top: 1px;
  width: 91%;
  border-radius: 5px;
}

.persionalInfoBackColorImg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 94px;
}

.headPhoto {
  margin-left: 30px;
  width: 94px;
  height: 94px;
  border: 1px solid #ccc;
  border-radius: 48px;
  overflow: hidden;
}

.avatar {
  width: 100%;
  height: 100%;
}

.info {
  margin: 40px 0 0 10px;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  color: #ffffff;
  /* border: 1px solid #ccc; */
}

.myAction {
  margin: 0 auto;
  margin-top: 20px;
  width: 91%;
  border-radius: 6px;
  /* border: 1px solid #ccc; */
}

.myAction div {
  position: relative;
  width: 100%;
  height: 64px;
  line-height: 64px;
  margin: 0 auto;
  margin-top: 15px;
  text-align: left;
  text-indent: 0.5rem;
  color: #ffffff;
  font-size: 14px;
  border-radius: 10px;
  background-color: #22242b;
}

.myAction div .actionLink {
  position: absolute;
  right: 15px;
  top: 35%;
}

.myAction div div {
  font-size: 18px;
  margin-top: 15px;
}

.myAction .asideMenuTopBoxItemSelected {
  flex: 1;
  margin: 0 5px;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  border-radius: 5px;
  background-color: #30323b;
}

.myAction .asideMenuTopBoxItemSelected div {
  font-size: 18px;
  margin-top: 4px;
  background-color: #30323b;
}

.operation {
  margin: 15px auto;
  width: 88%;
  border-radius: 6px;
  background-color: #22242b;
}

.changePhotoBox {
  margin: 10px auto;
  margin-top: 30px;
  width: 85px;
  height: 85px;
}

.usernameAndGenderInfo {
  margin: 20px auto;
  width: 80%;
  height: 80px;
  border-radius: 6px;
  /* border: 1px solid #ccc; */
}

.bottomBtn {
  display: flex;
  margin: 0px auto;
  margin-top: 20px;
  width: 80%;
  height: 40px;
  /* background-color: skyblue; */
}

.bottomBtn button {
  flex: 1;
  font-size: 14px;
}

.languageItem {
  position: relative;
  margin: 0 auto;
  margin-top: 4px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  /* text-indent: 5rem; */
  text-align: center;
  background-color: #2e313b;
}

.flag {
  position: absolute;
  top: 10px;
  left: 30px;
  width: 40px;
  height: 30px;
  /* background-color: aqua; */
  border-radius: 8px;
  overflow: hidden;
}

.flag img {
  width: 100%;
  height: 100%;
}

.usernameAndGenderInfo .van-field__control {
  color: white;
}

.usernameAndGenderInfo .van-field__label {
  color: white;
}

.arrow {
  position: absolute;
  top: 22px;
  right: 8px;
  width: 30px;
  height: 30px;
}

.copyIcon {
  margin: 0px 15px 0;
  width: 14px;
  vertical-align: middle;
}

.rankListByGameBox {
  display: flex;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 91%;
  height: 105px;
  background-color: #22242b;
  border-radius: 15px;
  border: 2px solid #dd33f1;
  overflow: hidden;
}

.rankListByGameItemLeft {
  position: relative;
  flex: 0.8;
  margin: 0 auto;
  width: 100%;
  min-height: 110px;
  /* min-height: 130px; */
  border-radius: 10px;
  overflow: hidden;
}

.rankListGameItem {
  position: absolute;
  top: 9%;
  left: 8%;
  margin: 0px auto;
  border-radius: 10%;
  height: 80%;
  overflow: hidden;
}

.rankListGameItem .gameItemBoxNormal .gameItemBackImg {
  position: absolute;
  bottom: 23%;
  left: -1px;
  margin: 0 auto;
  width: 102%;
  height: 45%;
}

.rankListGameItem .gameItemBoxNormal .gameImg {
  width: 100%;
  max-height: 117px;
  border-radius: 15px;
  overflow: hidden;
}

.rankListGameItem .gameItemBoxNormal .gameName {
  position: absolute;
  top: 51%;
  width: 100%;
  color: white;
  font-size: 12px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 7%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rankListByGameItemRight {
  flex: 1.2;
  /* width: 56%;
  height: 100px; */
  border-radius: 10px;
  overflow: hidden;
}

.rankListImgPosition {
  margin: 0 auto;
  margin-top: 8%;
  width: 88%;
  height: 80%;
  border-radius: 10%;
  overflow: hidden;
}

.rankListImg {
  width: 100%;
}

.rankListByGameItemBonusDetail {
  margin: 0 auto;
  margin-top: 5%;
  width: 90%;
  height: 80%;
}

.rankListBonusInfoBox {
  position: relative;
  margin: 0 auto;
  margin-top: 15px;
  width: 91%;
  height: 31px;
  line-height: 31px;
  overflow: hidden;
  background-image: url("../../assets/rankListImg.png");
  background-size: cover;
  background-position: center;
}

.playMoreWinMore {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.idTypeShow {
  position: absolute;
  bottom: 15%;
  right: 1%;
  min-width: 106px;
  font-size: 12px;
  text-align: center;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.usernameShow {
  position: absolute;
  bottom: 15%;
  left: 9.4%;
  min-width: 85px;
  max-width: 106px;
  font-size: 14px;
  color: white;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.switchAccountButtion {
  position: absolute;
  top: 50px;
  right: 5px;
  width: 106px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
  background-color: #723282;
  color: white;
  overflow: hidden;
}

.rankListHotAd {
  margin: 10px auto;
  width: 91%;
  height: 30px;
  border-radius: 10px;
  color: #c192e2;
  font-weight: bold;
}

.classifyNavBox {
  margin: 0 auto;
  display: inline-block;
  max-width: 88%;
  height: 32px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  border-radius: 6px;
}

.classifyItem {
  display: inline-block;
  position: relative;
  margin-right: 4px;
  width: 60px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  font-size: 10px;
  background-color: #441e71;
  color: white;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  background-image: linear-gradient(to top, #451e72, #231335 81%);
  border-bottom: 2px solid #9b43ff;
}

.profileGameBox {
  margin: 0 auto;
  width: 91%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  border-radius: 8px;
}

.profileGameBox::-webkit-scrollbar {
  display: none;
}

.profileGameItem {
  position: relative;
  display: inline-block;
  width: 31%;
  margin: 0 6px 0 0;
}

.rankListGameItemHotIcon {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40px;
  height: 20px;
  font-size: 9px;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 10px;
  background-color: orange;
  color: white;
  z-index: 2;
  background-image: linear-gradient(to bottom, #9338fa, #c000f4 100%);
}



.changeNicknameBox {
  position: relative;
  margin: 0 auto;
  padding-top: 1px;
  width: 100%;
  height: 100%;
  min-height: 250px;
  background-image: url("../../assets/changeNicknameBackImg.png");
  background-size: cover;
  background-position: center;
}

.changeNicknameBoxTop {
  position: absolute;
  top: 9%;
  left: 50%;
  margin-left: -40px;
  width: 80px;
  height: 80px;
  border-radius: 7px;
  background-image: url("../../assets/photo.png");
  background-size: cover;
  background-position: center;
}

.changeNicknameBoxCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: white;
}

.changeNicknameIpu {
  width: 60%;
  height: 35px;
  background: none;
  border-radius: 7px;
  border: 2px solid #636066;
  outline: none;
  text-align: center;
}

.changeNicknameBoxBottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  text-align: center;
}

.changeNicknameBtn {
  width: 110px;
  font-size: 12px;
  border-radius: 7px;
  color: white;
  background-image: linear-gradient(to right, #ff48ef, #c000f4 100%);
  border: none;
}
</style>
