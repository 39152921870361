import Mini80 from '../components/home/Mini80.vue'
import Vegasjackpots777 from '../components/home/Vegasjackpots777.vue'
import Happysky from '../components/home/Happysky.vue'
import Profile from '../components/profile/Profile.vue'
// import Slideshow from '../components/Slideshow.vue'
// import Nav from '../views/Nav.vue'
import Main from '../views/Main.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import UserAction from '../views/UserAction.vue'
import AllGameByClassify from '../views/AllGameByClassify.vue'


import VueRouter from 'vue-router'
import Vue from 'vue'

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Main,
            // 重定向
            // redirect: '/mini80/menu',
            redirect: '/home/menu',
            children: [
                { path: '/home/menu', component: Mini80 },
                { path: '/vegasjackpots777/menu', component: Vegasjackpots777 },
                { path: '/happysky/menu', component: Happysky },
                { path: '/profile/info', component: Profile },
            ]

        },
        // {
        //     path: '/login', component: Login
        // },
        // {
        //     path: '/register', component: Register
        // },
        // {
        //     path: '/user/action', component: UserAction
        // },
        {
            path: '/all/games', component: AllGameByClassify
        }

    ]
})

Vue.use(VueRouter)
