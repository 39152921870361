import Vue from 'vue'
import Vuex from 'vuex'
import { Toast } from 'vant'
Vue.use(Vuex)

// 改变变量值的方法（异步）
const actions = {

}

// 改变变量值的方法（同步）
const mutations = {
    addTagList(state, val) {
        if (val.name !== 'home') {
            let index = state.tagList.findIndex(item => item.name === val.name)
            if (index === -1) {
                state.tagList.push(val)
            }
        }
    },
    userActionchange(state, val) {
        state.userAction = val
    },
    addHistoryRecords(state, val) {
        state.historyData = val
    },
    showLoginActionSheet(state, val) {
        state.isLoginActionSheet = val
    },
    showInstallTip(state, val) {
        state.isInstallApp = val
    },
    initialApp(state, obj) {
        state.installAppObject = obj
    },
    installApp(state, obj) {
        if (state.installAppObject != null) {
            state.installAppObject.prompt()
        } else {
            Toast({
                duration: 4000,
                message: obj.$t("common.userAction.installed")
            })
        }
    },
    changeLoginStatus(state, val) {
        state.isLogin = val
    },
}

const state = {
    isCollapse: false,
    isLoginActionSheet: false,
    isInstallApp: false,
    installAppObject: null,
    isLogin: false,
    tagList: [
        {
            path: '/home/menu',
            name: 'home',
            label: '首页',
            icon: 's-home',
            url: 'Home/Home'
        },
    ],
    userAction: 'favorite',
    historyData: [],
    favoriteData: [],
}

const Store = new Vuex.Store({
    actions,
    mutations,
    state
})

export default Store
