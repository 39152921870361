<template>
  <!-- <div :class="isMainHome ? 'mainBox isMenuBox' : 'mainBox'" :style="{ -->
  <div class='mainBox isMenuBox' :style="{
    // height: pageHeight,
    width: pageWidth,
    backgroundColor: backColor,
    // backgroundImage: isMainHome ? gradient : ''
  }">

    <van-sticky>
      <Navi ref="Navi"></Navi>
    </van-sticky>

    <main>
      <router-view></router-view>
    </main>

    <van-sticky v-if="!$store.state.isLogin">
      <div class="loginTipPisition">
        <LoginTip></LoginTip>
      </div>
    </van-sticky>

    <footer class="bottom">
      <Bottom></Bottom>
    </footer>

  </div>
</template>

<script>
import Navi from "../views/Navi";
import Bottom from "../components/bottom/Bottom.vue";
import LoginTip from "../components/logintip/LoginTip.vue";
import { mapState, mapActions } from "vuex";
import { Toast } from "vant";
export default {
  data() {
    return {
      accessId: "",
      orderedDetail: {},
      seeOrderedDialog: false,
      isMainHome: false,
      isLoginActionSheet: true,
      pageHeight: "",
      pageWidth: "",
      backColor: "#060c18",
      // gradient: 'linear-gradient(to bottom left, #a21b80, #080d1b 60%)',

      beforeinstallprompt: null,
    };
  },

  computed: {
    ...mapState({
      count: (state) => state.isLoginActionSheet,
    }),
  },


  // 监听器
  watch: {
    $route: function (to, from) {
      this.isShowGradient();
    },
  },

  methods: {

    changeLoginStatus(e) {
      this.$store.commit("changeLoginStatus", e);
    },

    checkGoogleIsLogin() {
      let fullPath = this.$route.fullPath
      let beginIndex = fullPath.lastIndexOf('?')
      let endIndex = fullPath.lastIndexOf('=')

      if (beginIndex == -1 || endIndex == -1) {
        return
      }
      let isSuccess = fullPath.slice(beginIndex + 1, endIndex)


      if (isSuccess == 'error') {
        // Toast('')
        return
      }
      let userid = fullPath.slice(endIndex + 1)
      // let email = string.replace(/%40/g, '@');

      this.$axios
        .get(this.$Host + "/client/user/signInById?id=" + userid)
        .then((res) => {
          if (res.data.code == 10041) {
            res.data.data.isTourist = false
            localStorage.setItem(
              "7topgame.com-user-info",
              JSON.stringify(res.data.data)
            );
            Toast(this.$t("common.axios.signInSuccessful"))
            this.changeLoginStatus(true)
            this.$router.push('/')
            return
          }
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"))
        });
    },

    generateTourist() {
      let tourist = {
        id: null,
        gender: "",
        name: "",
        avatar: "",
      };
      this.$axios
        .post(this.$Host + "/client/tourist/signInWithTourist", tourist)
        .then((res) => {
          if (res.data.code == 10011) {
            Toast(this.$t("common.axios.identity"))
            res.data.data.isTourist = true
            localStorage.setItem(
              "7topgame.com-user-info",
              JSON.stringify(res.data.data)
            );
            this.changeLoginStatus(false)
          }
        })
        .catch((err) => {
        });
    },

    isShowGradient() {
      if (this.$route.path.includes("/profile")) {
        this.isMainHome = false;
      }
      if (this.$route.path.includes("/home")) {
        this.isMainHome = true;
      }
    },

    visitSite() {
      let userInfo = localStorage.getItem('7topgame.com-user-info')
      let tid = userInfo == '{}' ? 0 : JSON.parse(userInfo).id
      this.$axios.get(this.$Host + "/common/browsingRecord/insert?nums=1" + '&tid=' + tid).then(res => {
      }).catch(error => {

      })
    },

    async fbSignIn() {
      this.$axios
        .get(
          "https://graph.facebook.com/oauth/access_token?client_id=421990884296462&client_secret=1efdf904170a64c1372c265d900f8f59&grant_type=client_credentials"
        )
        .then((res) => {
          console.log(res.data);
          this.$axios
            .get(
              // "https://graph.facebook.com/v21.0/me?fields=id,name,email&access_token=" + res.data.access_token + '&appsecret_proof=1efdf904170a64c1372c265d900f8f59'
              "https://graph.facebook.com/me?access_token=" +
              res.data.access_token
            )
            .then((res) => {
            });
        })
        .catch((err) => {
          Toast(err);
        });
    },

  },


  mounted() {
    let userInfo = JSON.parse(localStorage.getItem('7topgame.com-user-info'))

    if (userInfo == null || JSON.stringify(userInfo) == '{}') {
      this.generateTourist()
    }

    if (userInfo.isTourist) {
      this.changeLoginStatus(false)
    }else{
      this.changeLoginStatus(true)
    }

    this.visitSite()

    this.checkGoogleIsLogin()

    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.register("/serviceworker.js");
    // }

    // this.fbSignIn()

    let height = window.innerHeight;
    let width = window.innerWidth;
    this.pageHeight = height + "px";
    this.pageWidth = width + "px";
  },



  components: {
    Navi,
    Bottom,
    LoginTip,
  },
};
</script>

<style>
.el-header {
  padding: 0;
  margin: 0;
}

.mainPage {
  padding: 1px;
}

.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  z-index: 2;
  /* background-color: #1D2027; */
}

.mainBox {
  position: relative;
  overflow: hidden;
  /* background-image: url('https://s3.us-east-2.amazonaws.com/slotspots777.click/7topgame/cover/bees-love-synthesis.png'); */
  /* background: radial-gradient(circle, #a21b80,#080d1b); */
  /* background-image: linear-gradient(to bottom, #3d6d2d , #2452d3 100%); */
}

.isMenuBox {
  /* background-image: url('https://s3.us-east-2.amazonaws.com/slotspots777.click/7topgame/cover/bees-love-synthesis.png'); */
  background-image: url("../assets/mainBackimg.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
}

.fixedBox {
  position: sticky;
  top: 1px;
  height: 56px;
}

.loginPageImg {
  margin: 0 auto;
  margin-top: 13px;
  width: 91%;
  height: 140px;
  background-color: #100c1e;
  border-radius: 12px;
  overflow: hidden;
}

.loginMethodBox {
  margin: 0 auto;
  margin-bottom: 20px;
  width: 70%;
  height: 160px;
}

.loginMethodBox .loginMethodItem {
  width: 240px;
  min-height: 42px;
}

.loginMethodItem .van-button--info {
  background-color: #c520d9;
  border: 1px solid #c520d9;
}

.loginMethodBox .van-divider {
  margin-bottom: 0;
}

.loginTipPisition {
  position: fixed;
  bottom: 77px;
  width: 100%;
  height: 35px;
  z-index: 3;
}
</style>
